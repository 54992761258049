import React from "react";
import styles from '../../css/sb-admin-2.module.css'
import '../../css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'

class Product extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            products: [],
            inventors: [],
            collections: [],
            activeItem: {
                ip: null,
                name: '',
                reference_number: '',
                price: '',
                material_price: '',
                vendor_commission_price: '',
                service_commission_price: '',
                digital: '',
                status: '',
                collection_id: '',
                inventory_id: '',
                product_detail_id: this.getProductDetailId(),
            },
            editing: false,
            showModal: false,
            showDeleteModal: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.fetchProducts = this.fetchProducts.bind(this)
        this.fetchIventory = this.fetchIventory.bind(this)
        this.fetchCollections = this.fetchCollections.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openDeleteModal = (role) => {
        this.setState({
            roleItem: role,
            editing: true,
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    fetchIventory() {
        fetch('http://localhost:8000/api/get-inventories/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    inventors: data
                })
            )
    }

    fetchCollections() {
        fetch('http://localhost:8000/api/get-collections/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    collections: data
                })
            )
    }

    fetchProducts() {
        fetch('http://localhost:8000/api/get-product/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    products: data
                })
            )
    }

    componentWillMount() {
        this.fetchCollections()
        this.fetchProducts()
        this.fetchIventory()
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            roleItem: {
                ...this.state.roleItem,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    startEdit(role) {
        this.setState({
            roleItem: role,
            editing: true,
        });

        this.openModal()
    }

    handleEdit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = `http://localhost:8000/api/inventories/${this.state.roleItem.id}/update/`;


        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.roleItem)
        }).then((response) => {
            this.fetchIventory()
            this.setState({
                activeItem: {
                    ip: null,
                    name: '',
                    reference_number: '',
                    price: '',
                    material_price: '',
                    vendor_commission_price: '',
                    service_commission_price: '',
                    digital: '',
                    status: '',
                    collection_id: '',
                    inventory_id: '',
                    product_detail_id: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    getProductDetailId = () => {
        var url = window.location.href
        var arrayUrl = url.split('/')
        var clientId = arrayUrl[5]
        return clientId
    }

    handleSubmit(e) {
        console.log(this.state.activeItem);
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = 'http://localhost:8000/api/product/';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchIventory()
            this.setState({
                activeItem: {
                    ip: null,
                    name: '',
                    reference_number: '',
                    price: '',
                    material_price: '',
                    vendor_commission_price: '',
                    service_commission_price: '',
                    digital: '',
                    status: '',
                    collection_id: '',
                    inventory_id: '',
                    product_detail_id: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })
    }

    deleteItem(role) {
        var csrftoken = this.getCookie('csrftoken');

        fetch(`http://localhost:8000/api/inventories/${role.id}/delete/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.fetchIventory();
        })

        this.closeDeleteModal()
    }

    render() {
        var self = this
        var inventors = self.state.inventors
        var products = self.state.products
        var collections = self.state.collections

        console.log(products);
        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Products</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Products</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Name</label>
                                                <input type="text" name='name' value={this.state.activeItem.name} onChange={this.handleChange} class="form-control"
                                                    placeholder="product name" required />
                                            </div>

                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Reference Number</label>
                                                <input type="text" name='reference_number' value={this.state.activeItem.reference_number} onChange={this.handleChange} class="form-control"
                                                    placeholder="reference number" required />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Price</label>
                                                <input type="number" name='price' value={this.state.activeItem.price} onChange={this.handleChange} class="form-control"
                                                    placeholder="price" required />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Material Price</label>
                                                <input type="number" name='material_price' value={this.state.activeItem.material_price} onChange={this.handleChange} class="form-control"
                                                    placeholder="material_price" required />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Vendor Commission Price</label>
                                                <input type="number" name='vendor_commission_price' value={this.state.activeItem.vendor_commission_price} onChange={this.handleChange} class="form-control"
                                                    placeholder="vendor_commission_price" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Service Commission Price</label>
                                                <input type="number" name='service_commission_price' value={this.state.activeItem.service_commission_price} onChange={this.handleChange} class="form-control"
                                                    placeholder="service_commission_price" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                        <label for="task" class="form-label">Digital</label>
                                            <select name="digital" onChange={self.handleChange} class="form-control mb-3"
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Digital</option>
                                                <option value={1}>True</option>
                                                <option value={0}>False</option>
                                                
                                            </select>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Status</label>
                                                <input type="text" name='status' value={this.state.activeItem.status} onChange={this.handleChange} class="form-control"
                                                    placeholder="status" required />
                                            </div>
                                        </div>


                                        <div class="col-md-4">
                                        <label for="task" class="form-label">Collection</label>
                                            <select name="collection_id" onChange={self.handleChange} class="form-control mb-3"
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the collection</option>
                                                {collections.map(function (collection, index) {
                                                    return (
                                                        <option value={collection.id}>{collection.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <div class="col-md-4">
                                        <label for="task" class="form-label">Inventory</label>
                                            <select name="inventory_id" onChange={self.handleChange} class="form-control mb-3"
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the inventory</option>
                                                {inventors.map(function (inventory, index) {
                                                    return (
                                                        <option value={inventory.id}>{inventory.description}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="submit" id='submit' name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">details</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Reference Number</th>
                                            <th>Price</th>
                                            <th>Material Price</th>
                                            <th>Vendor Commission Price</th>
                                            <th>Service Commission Price</th>
                                            <th>Digital</th>
                                            <th>Status</th>
                                            <th>collection name</th>
                                            <th>Inventory</th>
                                            <th>created on</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Reference Number</th>
                                            <th>Price</th>
                                            <th>Material Price</th>
                                            <th>Vendor Commission Price</th>
                                            <th>Service Commission Price</th>
                                            <th>Digital</th>
                                            <th>Status</th>
                                            <th>collection name</th>
                                            <th>Inventory</th>
                                            <th>created on</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {products.map(function (product, index) {
                                            var date = new Date(product.created_on)
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{product.name}</td>
                                                    <td>{product.reference_number}</td>
                                                    <td>{product.price}</td>
                                                    <td>{product.material_price}</td>
                                                    <td>{product.vendor_commission_price}</td>
                                                    <td>{product.service_commission_price}</td>
                                                    <td>{product.digital}</td>
                                                    <td>{product.status}</td>
                                                    <td>{product.collection}</td>
                                                    <td>{product.inventory}</td>
                                                    <td>{date.toLocaleDateString()}</td>
                                                    <td>
                                                        <div class="actions-container flex">
                                                            <a href={"/administration/products/" + product.id} class="custom-action-button" style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-eye"></i>
                                                            </a>
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.openStaffDetailsModal(product)} class="custom-action-button">
                                                                <i class="fas fa fa-edit"></i>
                                                            </a>
                                                            <a class="custom-action-button" onClick={() => self.openDeleteModal(product)} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-trash-alt"></i>
                                                            </a>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>


                {/* ====== MODALS ====== */}
                
        

            </>

        )
    }

}

export default Product