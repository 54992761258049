import React from "react";
import styles from './css/sb-admin-2.module.css'
import './css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'
import jsPDFInvoiceTemplate, { OutputType, jsPDF } from "jspdf-invoice-template"
import skyJacketLogo from './media/logo-1.jpeg'

class StaffDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            staff: [],
            staffDetails: [],
            details: {
                idStaff: null,
                key: '',
                type: '',
                value: '',
            },
            editingDetails: {
                id: null,
                type: '',
                value: '',
                idStaff: null,
            },
            showStaffDetailsModal: false,
            showDeleteModal: false,
        }
        this.fetchStaff = this.fetchStaff.bind(this)
        this.fetchStaffDetails = this.fetchStaffDetails.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeOnEditDetails = this.handleChangeOnEditDetails.bind(this)
        this.handleEditDetails = this.handleEditDetails.bind(this)
    }


    componentWillMount() {
        this.fetchStaff()
        this.fetchStaffDetails()
    }

    getStaffId() {
        var url = window.location.href
        var arrayUrl = url.split('/')
        var staffId = arrayUrl[5]
        return staffId
    }

    openStaffDetailsModal = (detail) => {

        this.setState({
            editingDetails: detail,
            showStaffDetailsModal: true
        });
    };

    closeStaffDetailsModal = () => {
        this.setState({ showStaffDetailsModal: false });
    };

    openDeleteModal = (detail) => {
        this.setState({
            editingDetails: detail,
            showDeleteModal: true
        });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    fetchStaff() {
        fetch('https://skyjacket.co.za/api/staff/' + this.getStaffId())
            .then(response => response.json())
            .then(data =>
                this.setState({
                    staff: data
                })
            )
    }

    fetchStaffDetails() {
        fetch('https://skyjacket.co.za/api/staff-details/' + this.getStaffId())
            .then(response => response.json())
            .then(data =>
                this.setState({
                    staffDetails: data
                })
            )
    }

    handleChange(e) {
        var { name, value } = e.target

        this.state.details.idStaff = this.state.staff.id

        this.setState({
            details: {
                ...this.state.details,
                [name]: value,
            }
        })
    }

    handleChangeOnEditDetails(e) {
        var { name, value } = e.target

        this.state.editingDetails.idStaff = this.getStaffId()

        this.setState({
            editingDetails: {
                ...this.state.editingDetails,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    handleEditDetails(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = `https://skyjacket.co.za/api/update-staff-details/${this.state.editingDetails.id}/`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.editingDetails)
        }).then((response) => {
            this.fetchStaffDetails()
            this.setState({
                details: {
                    id: null,
                    type: '',
                    value: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeStaffDetailsModal()
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state.details);
        var csrftoken = this.getCookie('csrftoken');

        var url = 'https://skyjacket.co.za/api/post-staff-details/';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.details)
        }).then((response) => {
            this.fetchStaffDetails()
            this.setState({
                details: {
                    id: null,
                    type: '',
                    value: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

    }

    deleteStaffDetail(detail) {
        var csrftoken = this.getCookie('csrftoken');

        fetch(`https://skyjacket.co.za/api/delete-staff-detail/${detail.id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.fetchStaffDetails();
        })

        this.closeDeleteModal()
    }

    pdfGenerator = () => {

        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = dataAtual.getMonth() + 1;
        const dia = dataAtual.getDate();

        var props = {
            outputType: OutputType.Save,
            returnJsPDFDocObject: true,
            fileName: this.state.staff.username + " information",
            orientationLandscape: false,
            compress: true,
            logo: {
                src: skyJacketLogo,
                type: 'JPEG',
                width: 53.33,
                height: 26.66,
                margin: {
                    top: 0,
                    left: 0
                }
            },
            business: {
                name: "SkyJacket",
                address: "address",
                phone: "(+258) 87 521 7383",
                email: "support@skyjacket.co",
                website: "www.skyjacket.co",
            },
            contact: {
                label: "Customer data:",
                name: this.state.staff.first_name + ' ' + this.state.staff.last_name,
                email: this.state.staff.email,
            },
            invoice: {
                invDate: `date: ${dia}/${mes}/${ano}`,
                headerBorder: false,
                tableBodyBorder: false,
                header: [
                    {
                        title: "#",
                        style: {
                            width: 10
                        }
                    },
                    {
                        title: "data type",
                        style: {
                            width: 30
                        }
                    },
                    {
                        title: "data value",
                        style: {
                            width: 80
                        }
                    },
                ],
                table: Array.from(this.state.staffDetails, (detail, index) => ([
                    index + 1,
                    detail.type,
                    detail.value,
                ])),
            },
            pageEnable: true,
            pageLabel: "Page ",
        };

        const pdfObject = jsPDFInvoiceTemplate(props)
    }

    render() {

        var self = this
        var staff_details = this.state.staffDetails
        console.log(this.getStaffId());
        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Details of {this.state.staff.username}</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Add Staff Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div className={`${styles.formGroup}`}>
                                                <input type="text" name='key' value={this.state.details.key} onChange={this.handleChange} className={styles.formControl}
                                                    placeholder="key" required />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div className={`${styles.formGroup}`}>
                                                <input type="text" name='type' value={this.state.details.type} onChange={this.handleChange} className={styles.formControl}
                                                    placeholder="type" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div className={`${styles.formGroup}`}>
                                                <input type="text" name='value' value={this.state.details.value} onChange={this.handleChange} className={styles.formControl}
                                                    placeholder="value" required />
                                            </div>
                                        </div>

                                        <div>
                                            <div class="form-group">
                                                <input type="submit" id='submit' name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">details</h6>
                            <button className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }} onClick={this.pdfGenerator}>Generate PDF</button>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>key</th>
                                            <th>type</th>
                                            <th>value</th>
                                            <th>created at</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>key</th>
                                            <th>type</th>
                                            <th>value</th>
                                            <th>created at</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {staff_details.map(function (detail, index) {
                                            var date = new Date(detail.created_at)
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{detail.key}</td>
                                                    <td>{detail.type}</td>
                                                    <td>{detail.value}</td>
                                                    <td>{date.toLocaleDateString()}</td>
                                                    <td>
                                                        <div class="actions-container flex">
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.openStaffDetailsModal(detail)} class="custom-action-button">
                                                                <i class="fas fa fa-edit"></i>
                                                            </a>
                                                            <a class="custom-action-button" onClick={() => self.openDeleteModal(detail)} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                {/* ====== MODALS ====== */}
                <Modal show={this.state.showStaffDetailsModal} onHide={this.closeStaffDetailsModal}>
                    <form onSubmit={this.handleEditDetails}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.editingDetails.username} Staff Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Type</label>
                                    <input type="text" name='type' value={this.state.editingDetails.type} onChange={this.handleChangeOnEditDetails} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Value</label>
                                    <input type="text" name='value' value={this.state.editingDetails.value} onChange={this.handleChangeOnEditDetails} class="form-control" required />
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeStaffDetailsModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Staff Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete staff detail "{this.state.editingDetails.type}"?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteStaffDetail(this.state.editingDetails)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default StaffDetails