import React from "react";

class Landing extends React.Component {

    render() {
        return (
            <>
                <header class="masthead">
                    <div class="container">
                        <div class="masthead-subheading">Welcome to Sky Jacket!</div>
                        <a class="btn btn-primary btn-xl text-uppercase" href="#services">Get to know us</a>
                    </div>
                </header>

                <section class="page-section" id="services">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-heading text-uppercase">Our services</h2>
                            <h3 class="section-subheading text-muted">Help us build the future of afro wear</h3>
                        </div>
                        <div class="row text-center">
                            <div class="col-md-4">
                                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/landing/tailor.jpg"
                                    alt="..." /></div>
                                <h4 class="my-3">Tailoring</h4>
                                <p class="text-muted">Do you have a pair of pant you love, but the zipper just broke?
                                    Bring it to SJ, we'll replace that for you. How about that wedding that is around the
                                    corner, do you need a suit or dress to look lavish in? You guessed it, SJ has got you covered.</p>
                            </div>
                            <div class="col-md-4">
                                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/landing/3d.png"
                                    alt="..." /></div>
                                <h4 class="my-3">3D design</h4>
                                <p class="text-muted">Look, we are living in a different world right now. A world which is
                                    constantly evolving. One of those forms of evolution is that of adopting 3D assets for real-life
                                    applications. Send us you idea or design and we will help you realize it.</p>
                            </div>
                            <div class="col-md-4">
                                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/landing/web.jpg"
                                    alt="..." /></div>
                                <h4 class="my-3">Web design</h4>
                                <p class="text-muted">Many small businesses have, in some way or another, seen how having a
                                    business website in todays economy can serve as security from unexpected business dips. But I
                                    don't know how to develop a website, you must be thinking. Well we do, and we are willing to
                                    do so for you too. </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Landing