import React from "react";
import styles from './css/sb-admin-2.module.css'
import './css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'

class Role extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            roleList: [],
            activeItem: {
                id: null,
                name: '',
                description: '',
            },
            roleItem: {
                id: null,
                name: '',
                description: '',
            },
            editing: false,
            showModal: false,
            showDeleteModal: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.fetchRole = this.fetchRole.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openDeleteModal = (role) => {
        this.setState({
            roleItem: role,
            editing: true,
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    fetchRole() {
        fetch('http://localhost:8000/api/role-list/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    roleList: data
                })
            )
    }

    componentWillMount() {
        this.fetchRole()
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            roleItem: {
                ...this.state.roleItem,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    startEdit(role) {
        this.setState({
            roleItem: role,
            editing: true,
        });

        this.openModal()
    }

    handleEdit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = `http://localhost:8000/api/api-update-role/${this.state.roleItem.id}/`;


        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.roleItem)
        }).then((response) => {
            this.fetchRole()
            this.setState({
                roleItem: {
                    id: null,
                    name: '',
                    description: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    handleSubmit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = 'http://localhost:8000/api/api-post-role/';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchRole()
            this.setState({
                activeItem: {
                    id: null,
                    name: '',
                    description: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })
    }

    deleteItem(role) {
        var csrftoken = this.getCookie('csrftoken');

        fetch(`http://localhost:8000/api/role-delete/${role.id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.fetchRole();
        })

        this.closeDeleteModal()
    }

    render() {
        var self = this
        var roles = self.state.roleList
        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Role</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Role</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-10">
                                            <div class="form-group">
                                                <input type="text" name='name' value={this.state.activeItem.name} onChange={this.handleChange} class="form-control"
                                                    placeholder="role designation" required />
                                            </div>
                                            <div class="form-group">
                                                <input type="text" name='description' value={this.state.activeItem.description} onChange={this.handleChange} class="form-control"
                                                    placeholder="role description" required />
                                            </div>
                                        </div>

                                        <div>
                                            <div class="form-group">
                                                <input type="submit" id='submit' name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">list of roles</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            <th>description</th>
                                            <th>created at</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            <th>description</th>
                                            <th>created at</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {roles.map(function (role, index) {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{role.name}</td>
                                                    <td>{role.description}</td>
                                                    <td>{role.created_at}</td>
                                                    <td>
                                                        <div class="actions-container flex">
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.startEdit(role)} class="custom-action-button">
                                                                <i class="fas fa fa-edit"></i>
                                                            </a>
                                                            <a class="custom-action-button" onClick={() => self.openDeleteModal(role)} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                {/* ====== MODALS ====== */}
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <form onSubmit={this.handleEdit} onHide={this.closeModal} show={this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Role {this.state.roleItem.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="col-sm-12">
                                <label for="task" class="form-label">Role</label>
                                <input value={this.state.roleItem.name} name='name' onChange={this.handleChangeOnEdit} type="text" class="form-control"
                                    placeholder="task title" required />
                            </div>

                            <div class="col-sm-12">
                                <label for="task" class="form-label">Description</label>
                                <input value={this.state.roleItem.description} onChange={this.handleChangeOnEdit} type="text" name='description' class="form-control"
                                    placeholder="task title" required />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Role {this.state.roleItem.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete {this.state.roleItem.name} role?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteItem(this.state.roleItem)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

export default Role