import React from 'react'
import './css/main.css'
import './css/util.css'
import './css/styles.css'
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import Landing from '../users_pages/Landing';
import AboutUs from '../users_pages/AboutUs';
import ContactUs from '../users_pages/ContactUs';
import $ from 'jquery'
import Store from '../users_pages/Store';
import ProductDetail from '../users_pages/ProductDetail';
import logo from '../admin/media/logo-1.jpeg'

class Template extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
        };
    }

    forceUpdate = () => {
        this.setState({ counter: this.state.counter + 1 });
    };

    getPathName() {
        var pathname = window.location.pathname.split('/')
        return pathname[1]
    }

    handleClick() {
        $(this).toggleClass('is-active');
        $('.menu-mobile').slideToggle();
    }

    render() {
        return (
            <>
                <Router>
                    {this.getPathName() !== 'login' ? (
                        <header>
                            <div class="container-menu-desktop">

                                <div class="wrap-menu-desktop bg-gradient-primary">
                                    <nav class="limiter-menu-desktop container">
                                        <Link to="/" class="logo">
                                            <img src={logo} alt="Sky Jacket" />
                                        </Link>
                                        <div class="menu-desktop">
                                            <ul class="main-menu">
                                                <li className={this.getPathName() === '' ? 'active-menu' : ''} onClick={this.forceUpdate}>
                                                    <NavLink to="/">Home</NavLink>
                                                </li>
                                                <li className={this.getPathName() === 'about_us' ? 'active-menu' : ''} onClick={this.forceUpdate} data-label1="">
                                                    <a href="/about_us">About</a>
                                                </li>

                                                <li className={this.getPathName() === 'contact_us' ? 'active-menu' : ''} onClick={this.forceUpdate} data-label1="">
                                                    <Link to="/contact_us">Contact</Link>
                                                </li>

                                                {/*<li className={this.getPathName() === 'store' ? 'active-menu' : ''} onClick={this.forceUpdate} data-label1="">
                                                    <Link to="/store">Store</Link>
                                                </li>*/}
                                            </ul>
                                        </div>

                                        <div class="wrap-icon-header flex-w flex-r-m">
                                            <li class="label" data-label1="">
                                                <a href="/login" className='btn btn-primary' onClick={this.forceUpdate}>Base Zero</a>
                                            </li>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div class="wrap-header-mobile bg-gradient-primary">
                                <div class="logo-mobile">
                                    <Link to="/"><img src={logo} alt="Sky Jacket" /></Link>
                                </div>

                                <div class="btn-show-menu-mobile hamburger hamburger--squeeze" onClick={() => this.handleClick()}>
                                    <span class="hamburger-box">
                                        <span class="hamburger-inner"></span>
                                    </span>
                                </div>
                            </div>

                            <div class="menu-mobile">

                                <ul class="main-menu-m">
                                    <li onClick={() => this.handleClick()}>
                                        <Link to="/">Home</Link>
                                    </li>

                                    <li onClick={() => this.handleClick()}>
                                        <Link to="/about_us" >About</Link>
                                    </li>

                                    <li onClick={() => this.handleClick()}>
                                        <Link to="/contact_us">Contact</Link>
                                    </li>

                                    <li onClick={() => this.handleClick()}>
                                        <Link to="/store/read">Store</Link>
                                    </li>

                                    <li onClick={() => this.handleClick()}>
                                        <a href="/login">Base Zero</a>
                                    </li>

                                </ul>
                            </div>

                            <div class="modal-search-header flex-c-m trans-04 js-hide-modal-search">
                                <div class="container-search-header">
                                    <button class="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search">
                                        <img src="{" alt="CLOSE" />
                                    </button>

                                    <form class="wrap-search-header flex-w p-l-15" method="post">

                                        <button class="flex-c-m trans-04">
                                            <i class="zmdi zmdi-search"></i>
                                        </button>
                                        <input class="plh3" type="text" name="search" placeholder="Search..." />
                                    </form>
                                </div>
                            </div>
                        </header>
                    ) : (
                        <></>
                    )}

                    <Routes>
                        <Route path='/' element={<Landing />}></Route>
                        <Route path='/about_us' element={<AboutUs />}></Route>
                        <Route path='/contact_us' element={<ContactUs />}></Route>
                        <Route path='/store' element={<Store />}></Route>
                        <Route path='/store/read/:productId' element={<ProductDetail />}></Route>
                    </Routes>
                </Router>
                <footer class="footer py-4">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-4 text-lg-start">Copyright &copy; Sky Jacket, Soc Unip, Lda 2022-2024</div>
                            <div class="col-lg-4 my-3 my-lg-0">
                                <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/sky.jacket/?hl=en"><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/search/top?q=sky%20jacket"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/sky-jacket/"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                            <div class="col-lg-4 text-lg-end">
                                <a class="link-dark text-decoration-none me-3" href="#!">Privacy policy</a>
                                <a class="link-dark text-decoration-none me-3" href="#!">Terms of use</a>
                                <a class="link-dark text-decoration-none" id="text_call_login_page" href="#!" style={{fontStyle: 'italic'}}>Login</a>

                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}

export default Template