import React from 'react'
import styles from './css/sb-admin-2.module.css'
import './css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'

class Employee extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            employees: [],
            roles: [],
            activeItem: {
                first_name: '',
                last_name: '',
                email: '',
            },
            employee: {
                id: null,
                first_name: '',
                last_name: '',
                email: '',
            },
            editEmployee: {
				id: null,
				first_name: '',
				last_name: '',
				username: '',
				email: '',
				password: '',
				phone: '',
				status: '',
				country_id: '',
			},
			showModal: false,
			showDeleteModal: false,
			showEditModal: false,
            /*employeeDetails: {
                idEmployee: null,
                type: '',
                value: '',
            },*/
            showModal: false,
            showEditModal: false,
            showDeleteModal: false,
            //showEmployeeDetailsModal: false,
            show: false,
        }

        this.listAllEmployees = this.listAllEmployees.bind(this)
        //this.fetchRoles = this.fetchRoles.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.createEmployee = this.createEmployee.bind(this)
        this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.updateEmployee = this.updateEmployee.bind(this)
        //this.handleChangeOnEmployeeDetails = this.handleChangeOnEmployeeDetails.bind(this)
        //this.createEmployeeEmployeeDetails = this.createEmployeeEmployeeDetails.bind(this)
        this.origin = window.location.origin;
    }
    
    constructURL(destination) {
        var location = this.origin;
        return location + destination;
    }

    showToast = () => {
        this.setState({ show: true }, () => {
            setTimeout(() => {
                this.setState({ show: false });
            }, 5000);
        });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openEmployeeDetailsModal = (employee) => {

        this.setState({
            employeeDetails: {
                idEmployee: employee.id
            },
            employee: {
                id: employee.id
            },
            showEmployeeDetailsModal: true
        });
    };

    closeEmployeeDetailsModal = () => {
        this.setState({ showEmployeeDetailsModal: false });
    };

    openEditModal = (employee) => {
        this.setState({
            employee: employee,
            showEditModal: true
        });
    };

    closeEditModal = () => {
        this.setState({ showEditModal: false });
    };

    openDeleteModal = (employee) => {
        this.setState({
            employee: employee,
            editing: true,
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    componentWillMount() {
        this.listAllEmployees()
        //this.fetchRoles()
    }

    listAllEmployees() {
        fetch(this.constructURL("/api/list-employees/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    employees: data
                })
            )
    }

    fetchRoles() {
        fetch(this.constructURL("/api/list-roles/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    roles: data
                })
            )
    }
    
    handleChangeOnEmployeeDetails(e) {
        var { name, value } = e.target

        this.setState({
            employeeDetails: {
                ...this.state.employeeDetails,
                [name]: value,
            }
        })
    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            employee: {
                ...this.state.employee,
                [name]: value,
            }
        })
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    createEmployeeEmployeeDetails(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = this.constructURL("/api/create-employee-details/");

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.employeeDetails)
        }).then((response) => {
            this.listAllEmployees()
            this.setState({
                employeeDetails: {
                    id: null,
                    type: '',
                    value: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeEmployeeDetailsModal()
    }

    createEmployee(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = this.constructURL("/api/create-employee/");
        console.log("Employee should be created")
		console.log(this.state.activeItem)

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.listAllEmployees()
            this.setState({
                activeItem: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            })
            console.log("Employee should be created")
			console.log(this.state.activeItem)
			console.log(response)
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    updateEmployee(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');
        
        var employeeId = this.state.employee.id
		var url = this.constructURL("/api/update-employee/" + employeeId + "/");


        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.employee)
        }).then((response) => {
            this.listAllEmployees()
            this.setState({
                employee: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeEditModal()
    }

    deleteEmployee(employee) {
        var csrftoken = this.getCookie('csrftoken');
        var employeeId = this.state.employee.id
		var url = this.constructURL("/api/delete-employee/" + employeeId + "/");

        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.listAllEmployees();
        })

        this.closeDeleteModal()
    }

    render() {

        var self = this
        var employees = self.state.employees
        //var roles = self.state.roles

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Register</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <div class="d-flex justify-content-between">
                                <div class="flex-grow-1 mr-2">
                                    <h6 class="m-0 font-weight-bold text-primary">list of employee</h6>
                                </div>
                                <div class="flex-grow-1 ml-2">
                                    <button onClick={() => this.openModal()}
                                        className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>New Employee</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="example1" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            <th>email</th>
                                            <th>status</th>
                                            <th>date joined</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            <th>email</th>
                                            <th>status</th>
                                            <th>date joined</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {employees.map(function (employee, index) {
                                            var date = new Date(employee.date_joined)
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{employee.first_name + ' ' + employee.last_name}</td>
                                                        <td>{employee.email}</td>
                                                        {employee.is_superemployee ? (
                                                            <td>superemployee</td>
                                                        ) : (
                                                            <td>employee</td>
                                                        )}
                                                        <td>{date.toLocaleDateString()}</td>
                                                        <td>
                                                            <div class="actions-container flex">
                                                                <a onClick={() => self.openEditModal(employee)} class="custom-action-button" style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} >
                                                                    <i class="fas fa fa-edit"></i>
                                                                </a>
                                                                <a class="custom-action-button" onClick={() => self.openDeleteModal(employee)} style={{ cursor: 'pointer', color: 'grey' }}>
                                                                    <i class="fas fa fa-trash-alt"></i>
                                                                </a>
                                                                <a href={`/administration/employeedetails/${employee.id}`} class="custom-action-button" style={{ cursor: 'pointer', fontSize: '19px', color: 'grey' }}>
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                {/* ====== MODALS =======*/}
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <form onSubmit={this.createEmployee} onHide={this.closeModal} show={this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add new employee</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="first_name" class="form-label">First Name</label>
                                    <input type="text" name='first_name' value={this.state.activeItem.first_name} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="last_name" class="form-label">Last Name</label>
                                    <input type="text" name='last_name' value={this.state.activeItem.last_name} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" value={this.state.activeItem.email} name='email' onChange={this.handleChange} class="form-control" required />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showEditModal} onHide={this.closeEditModal}>
                    <form onSubmit={this.updateEmployee}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit employee {this.state.employee.first_name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">First Name</label>
                                    <input type="text" name='first_name' value={this.state.employee.first_name} onChange={this.handleChangeOnEdit} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Last Name</label>
                                    <input type="text" name='last_name' value={this.state.employee.last_name} onChange={this.handleChangeOnEdit} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Email</label>
                                    <input type="email" value={this.state.employee.email} name='email' onChange={this.handleChangeOnEdit} class="form-control" required />
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeEditModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Employee {this.state.employee.first_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete employee {this.state.employee.first_name}?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteEmployee(this.state.employee)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>

                {/* =========== TOASTS ===========*/}
                <ToastContainer position="top-end" className="p-3">
                    <Toast show={this.state.show} onClose={() => this.setState({ show: false })}>
                        <Toast.Header>
                            <img src="..." className="rounded mr-2" alt="..." />
                            <strong className="mr-auto">Bootstrap</strong>
                            <small>11 mins ago</small>
                        </Toast.Header>
                        <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
                    </Toast>
                </ToastContainer>

            </>
        )
    }
}

export default Employee