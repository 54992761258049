import React from "react";
import styles from '../css/sb-admin-2.module.css'
import '../css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'

export default class Location extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            locations: [],
            cities: [],
            showModal: false,
            showDeleteModal: false,
            activeItem: {
                id: null,
                address: '',
                postal_code: '',
                city_id: null,
            },
        }

        this.fetchCities = this.fetchCities.bind(this)
        this.fetchLocations = this.fetchLocations.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitOnEdit = this.handleSubmitOnEdit.bind(this)
        this.getCookie = this.getCookie.bind(this)
    }

    openModal = (item) => {
        this.setState({ showModal: true, activeItem: item });
    };

    closeModal = () => {
        this.setState({ showModal: false });
        this.clear()
    };

    openDeleteModal = (item) => {
        this.setState({
            activeItem: item
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    clear() {
        this.setState({
            activeItem: {
                id: null,
                address: '',
                postal_code: '',
                city_id: null,
            }
        })
    }

    componentWillMount() {
        this.fetchCities()
        this.fetchLocations()
    }

    fetchLocations() {
        fetch('https://skyjacket.co.za/api/get-locations/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    locations: data
                })
            )
    }

    fetchCities() {
        fetch('https://skyjacket.co.za/api/get-cities/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    cities: data
                })
            )
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    handleSubmitOnEdit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken')

        var url = `https://skyjacket.co.za/api/update-location/${this.state.activeItem.id}/`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchLocations()
            this.setState({
                activeItem: {
                    id: null,
                    address: '',
                    postal_code: '',
                    city_id: null,
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    handleSubmit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken')

        var url = `https://skyjacket.co.za/api/post-location/${this.state.activeItem.city_id}/`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchLocations()
            this.setState({
                activeItem: {
                    id: null,
                    address: '',
                    postal_code: '',
                    city_id: null,
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })
    }

    deleteItem(item) {
        var csrftoken = this.getCookie('csrftoken');

        fetch(`https://skyjacket.co.za/api/delete-location/${item.id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.fetchLocations();
        })

        this.closeDeleteModal()
    }

    render() {

        var self = this
        var cities = self.state.cities
        var locations = self.state.locations

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Location</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Location</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" name='address' value={this.state.activeItem.address} onChange={this.handleChange} class="form-control"
                                                    placeholder="address" required />
                                            </div>

                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" name='postal_code' value={this.state.activeItem.postal_code} onChange={this.handleChange} class="form-control"
                                                    placeholder="postal code" required />
                                            </div>

                                        </div>
                                        <div class="col-md-4">
                                            <select name="city_id" onChange={self.handleChange} class="form-control mb-3"
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the location</option>
                                                {cities.map(function (city, index) {
                                                    return (
                                                        <option value={city.id}>{city.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <input type="submit" id='submit' name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">list of roles</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Address</th>
                                            <th>Postal code</th>
                                            <th>City name</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {locations.map(function (location, index) {
                                            return (
                                                <tr>
                                                    <th>{index + 1}</th>
                                                    <th>{location.address}</th>
                                                    <th>{location.postal_code}</th>
                                                    <th>{location.city_name}</th>
                                                    <th>
                                                        <div class="actions-container flex">
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.openModal(location)} class="custom-action-button">
                                                                <i class="fas fa fa-edit"></i>
                                                            </a>
                                                            <a class="custom-action-button" onClick={() => self.openDeleteModal(location)} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </th>
                                                </tr>
                                            )
                                        })}

                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>city name</th>
                                            <th>state name</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <form onSubmit={this.handleSubmitOnEdit} onHide={this.closeModal} show={this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update State {this.state.activeItem.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Address</label>
                                    <input value={this.state.activeItem.address} name='address' onChange={this.handleChange} type="text" class="form-control"
                                        placeholder="address" required />
                                </div>

                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Postal code</label>
                                    <input value={this.state.activeItem.postal_code} name='postal_code' onChange={this.handleChange} type="text" class="form-control"
                                        placeholder="postal code" required />
                                </div>

                                <div class="col-md-12">
                                    <label for="task" class="form-label">state name</label>
                                    <select name="city_id" onChange={self.handleChange} class="form-control mb-3"
                                        aria-label="Default select example" id="role">
                                        <option disabled selected>Select the city</option>
                                        {cities.map(function (city, index) {
                                            return (
                                                <option value={city.id}>{city.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Location {this.state.activeItem.address}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete location {this.state.activeItem.address}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteItem(this.state.activeItem)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}