import React from "react";
import styles from '../css/sb-admin-2.module.css'
import '../css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'

class Order_set extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            inventors: [],
            customers: [],
            roleList: [],
            activeItem: {
                id: null,
                quantity: '',
                back_order: '',
                order_id: '',
                inventory_id: '',
            },
            roleItem: {
                id: null,
                quantity: '',
                back_order: '',
                order_id: '',
                inventory_id: '',
            },
            editing: false,
            showModal: false,
            showDeleteModal: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.fetchRole = this.fetchRole.bind(this)
        this.fetchBrands = this.fetchBrands.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openDeleteModal = (role) => {
        this.setState({
            roleItem: role,
            editing: true,
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    fetchRole() {
        fetch('https://skyjacket.co.za/api/get-orderset/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    roleList: data
                })
            )
    }

    fetchBrands() {
        fetch('https://skyjacket.co.za/api/get-clients/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    customers: data
                })
            )
    }

    fetchIventory() {
        fetch('https://skyjacket.co.za/api/get-inventories/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    inventors: data
                })
            )
    }

    componentWillMount() {
        this.fetchRole()
        this.fetchBrands()
        this.fetchIventory()
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            roleItem: {
                ...this.state.roleItem,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    startEdit(role) {
        this.setState({
            roleItem: role,
            editing: true,
        });

        this.openModal()
    }

    handleEdit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = `https://skyjacket.co.za/api/orderset/${this.state.roleItem.id}/update/`;


        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.roleItem)
        }).then((response) => {
            this.fetchRole()
            this.setState({
                roleItem: {
                    id: null,
                    quantity: '',
                    back_order: '',
                    order_id: '',
                    inventory_id: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    handleSubmit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = 'https://skyjacket.co.za/api/orderset/';

        console.log(this.state.activeItem);

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchRole()
        }).catch(function (error) {
            console.log('ERROR', error);
        })
    }

    deleteItem(role) {
        var csrftoken = this.getCookie('csrftoken');

        fetch(`https://skyjacket.co.za/api/orderset/${role.id}/delete/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.fetchRole();
        })

        this.closeDeleteModal()
    }

    render() {
        var self = this
        var roles = self.state.roleList
        var customers = self.state.customers
        var inventors = self.state.inventors
        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Order Set</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Order Set</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="status" class="form-label">Quantity</label>
                                                <input type="number" name='quantity' value={this.state.activeItem.quantity} onChange={this.handleChange} class="form-control"
                                                    placeholder="quantity" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="status" class="form-label">Back Order</label>
                                                <input type="number" name='back_order' value={this.state.activeItem.back_order} onChange={this.handleChange} class="form-control"
                                                    placeholder="back_order" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="status" class="form-label">Order</label>
                                                <input type="number" name='order_id' value={this.state.activeItem.order_id} onChange={this.handleChange} class="form-control"
                                                    placeholder="order_id" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="task" class="form-label">Inventory</label>
                                            <select name="inventory_id" onChange={self.handleChange} class="form-control mb-3"
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the inventory</option>
                                                {inventors.map(function (inventory, index) {
                                                    return (
                                                        <option value={inventory.id}>{inventory.description}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="submit" id='submit' name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">list of order set</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>quantity</th>
                                            <th>back order</th>
                                            <th>order</th>
                                            <th>inventory</th>
                                            <th>created at</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>quantity</th>
                                            <th>back order</th>
                                            <th>order</th>
                                            <th>inventory</th>
                                            <th>created at</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {roles.map(function (role, index) {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{role.quantity}</td>
                                                    <td>{role.back_order}</td>
                                                    <td>{role.order_id}</td>
                                                    <td>{role.inventory}</td>
                                                    <td>{role.created_on}</td>
                                                    <td>
                                                        <div class="actions-container flex">
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.startEdit(role)} class="custom-action-button">
                                                                <i class="fas fa fa-edit"></i>
                                                            </a>
                                                            <a class="custom-action-button" onClick={() => self.openDeleteModal(role)} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                {/* ====== MODALS ====== */}
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <form onSubmit={this.handleEdit} onHide={this.closeModal} show={this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Order x</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="status" class="form-label">Quantity</label>
                                    <input type="number" name='quantity' value={this.state.roleItem.quantity} onChange={this.handleChangeOnEdit} class="form-control"
                                        placeholder="quantity" required />
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="status" class="form-label">Back Order</label>
                                    <input type="number" name='back_order' value={this.state.roleItem.back_order} onChange={this.handleChangeOnEdit} class="form-control"
                                        placeholder="back_order" required />
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="status" class="form-label">Order</label>
                                    <input type="number" name='order_id' value={this.state.roleItem.order_id} onChange={this.handleChangeOnEdit} class="form-control"
                                        placeholder="order_id" required />
                                </div>
                            </div>

                            <div class="col-md-12">
                                <label for="task" class="form-label">Inventory</label>
                                <select name="inventory_id" onChange={self.handleChangeOnEdit} class="form-control mb-3"
                                    aria-label="Default select example" id="role">
                                    <option disabled selected>Select the inventory</option>
                                    {inventors.map(function (inventory, index) {
                                        return (
                                            <option value={inventory.id}>{inventory.description}</option>
                                        )
                                    })}
                                </select>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete order x?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteItem(this.state.roleItem)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

export default Order_set