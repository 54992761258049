import React, { useState } from "react";
//import styles from './css/sb-admin-2.module.css'
//import './css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import {
	Image,
	Container,
	Iframe,
	FilePreview,
	DragContainer,
	AceptedFiles,
} from '../styles/styles';
/* eslint-disable */
// @ts-nocheck


const DropzoneComponent: React.FC = () => {
	const [files, setFiles] = useState([]);

	const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
		useDropzone({
			//maximum number
			maxFiles: 5,
			// type of files
			accept: {
				"image/jpeg": [".jpeg", ".png"],
			},
			onDrop: (acceptedFiles) => {
				setFiles((prevFiles) => [
					...prevFiles,
					...acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					),
				]);
				console.log("Accepted Files: " + acceptedFiles);
				console.log("Files: " + files); // It will now contain the entire list of files
			},
		});
		
	const acceptedFileItems = acceptedFiles.map((file) => (
		<li key={file.name}>{file.name}</li>
	));
	const fileRejectionItems = fileRejections.map(({ file, errors }) => {
		return <li key={file.name}>{file.name}</li>
	});

	const Preview = files.map((file) => (
		<div>
			<Image src={file.preview} alt="preview" />
		</div>
	));

	return (
		<div className="container">
			<DragContainer {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<p>Drop something here...</p>
			</DragContainer>
			<br>
			</br>
				<AceptedFiles>Accepted Files {acceptedFileItems}</AceptedFiles>
			<br>
			</br>
			<FilePreview>
				{Preview}
			</FilePreview>
		</div>
	);

}
/*
<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="images" className="form-label">Images</label>
											<Dropzone
												getUploadParams={this.onUpload}
												PreviewComponent={null} // You can customize the preview component
												inputContent="Drop img or browse"
												accept="image/*"
												styles={{
													dropzone: { border: '2px dashed #cccccc', borderRadius: '4px', padding: '20px', textAlign: 'center', cursor: 'pointer', marginBottom: '20px' },
													inputLabel: { fontSize: '16px', color: 'black' },
												}}
											/>
										</div>
									</div>
*/

export default DropzoneComponent;
