import React from "react";
import styles from './css/sb-admin-2.module.css'
import './css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'

export default class Customer extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			countries: [],
			customers: [],
			activeItem: {
				first_name: '',
				last_name: '',
				username: '',
				email: '',
				password: '',
				phone: '',
				status: '',
				country_id: '',
			},
			editingDetails: {
				id: null,
				first_name: '',
				last_name: '',
				username: '',
				email: '',
				password: '',
				phone: '',
				status: '',
				country_id: '',
			},
			showModal: false,
			showDeleteModal: false,
			showEditModal: false,
		}

		this.fetchCountries = this.fetchCountries.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.createCustomer = this.createCustomer.bind(this)
		this.getCookie = this.getCookie.bind(this)
		this.fetchCustomers = this.fetchCustomers.bind(this)
		this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
		this.updateCustomer = this.updateCustomer.bind(this)
		this.origin = window.location.origin;
	}

	openDeleteModal = (customer) => {
		this.setState({
			editingDetails: customer,
			showDeleteModal: true
		});
	};

	closeDeleteModal = () => {
		this.setState({ showDeleteModal: false });
	};

	openEditModal = (customer) => {
		this.setState({
			editingDetails: customer,
			showEditModal: true
		});
	};

	closeEditModal = () => {
		this.setState({ showEditModal: false });
	};

	openModal = () => {
		this.setState({ showModal: true });
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};

	componentWillMount() {
		this.fetchCountries()
		this.fetchCustomers()
	}

	fetchCountries() {
		fetch(this.constructURL("/api/countries/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					countries: data
				})
			)
	}

	fetchCustomers() {
		fetch(this.constructURL("/api/list-customers/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					customers: data
				})
			)
	}

	handleChangeOnEdit(e) {
		var { name, value } = e.target

		this.setState({
			editingDetails: {
				...this.state.editingDetails,
				[name]: value,
			}
		})
	}

	handleChange(e) {
		var { name, value } = e.target

		this.setState({
			activeItem: {
				...this.state.activeItem,
				[name]: value,
			}
		})

	}

	getCookie(name) {
		var cookieValue = null;
		if (document.cookie && document.cookie !== '') {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i].trim();
				if (cookie.substring(0, name.length + 1) === (name + '=')) {
					cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
					break;
				}
			}
		}
		return cookieValue;
	}

	createCustomer(e) {
		e.preventDefault();

		var csrftoken = this.getCookie('csrftoken');

		var url = this.constructURL("/api/create-customer/");

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			},
			body: JSON.stringify(this.state.activeItem)
		}).then((response) => {
			this.fetchCustomers()
			this.setState({
				activeItem: {
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					country_id: '',
				}
			})
		}).catch(function(error) {
			console.log('ERROR', error);
		})

		this.closeModal()

	}

	updateCustomer(e) {
		e.preventDefault();

		var csrftoken = this.getCookie('csrftoken');
		var customerId = this.state.editingDetails.id
		var url = this.constructURL("/api/update-customer/" + customerId + "/");

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			},
			body: JSON.stringify(this.state.editingDetails)
		}).then((response) => {
			this.fetchCustomers()
			this.setState({
				editingDetails: {
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					status: '',
					country_id: '',
				}
			})
		}).catch(function(error) {
			console.log('ERROR', error);
		})

		this.closeEditModal()

	}

	deleteCustomer(customer) {
		var csrftoken = this.getCookie('csrftoken');
		var customerId = this.state.editingDetails.id
		var url = this.constructURL("/api/delete-customer/" + customerId + "/");

		fetch(url, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			}
		}).then((response) => {
			this.fetchCustomers();
		})

		this.closeDeleteModal()
	}

	constructURL(destination) {
		var location = this.origin
		return location + destination
	}

	render() {
		var self = this
		var countries = self.state.countries
		var customers = self.state.customers

		return (
			<>
				<div class="container-fluid">
					<p>
						<a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
					</p>
					<h1 class="h3 mb-2 text-gray-800">Customers</h1>
					<div class="card shadow mb-4">
						<div class="card-header py-3">
							<div class="d-flex justify-content-between">
								<div class="flex-grow-1 mr-2">
									<h6 class="m-0 font-weight-bold text-primary">list of customer</h6>
								</div>
								<div class="flex-grow-1 ml-2">
									<button onClick={() => this.openModal()}
										className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>New Customer</button>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="table-responsive">
								<table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="example1" width="100%" cellspacing="0">
									<thead>
										<tr>
											<th>#</th>
											<th>name</th>
											<th>status</th>
											<th>phone</th>
											<th>email</th>
											<th>country</th>
											<th>actions</th>
										</tr>
									</thead>
									<tfoot>
										<tr>
											<th>#</th>
											<th>name</th>
											<th>status</th>
											<th>phone</th>
											<th>email</th>
											<th>country</th>
											<th>actions</th>
										</tr>
									</tfoot>
									<tbody>
										{customers.map(function(customer, index) {
											return (
												<tr>
													<td>{index + 1}</td>
													<td>{customer.first_name + ' ' + customer.last_name}</td>
													<td>{customer.status}</td>
													<td>{customer.phone}</td>
													<td>{customer.email}</td>
													<td>{customer.country_name}</td>
													<td>
														<div class="actions-container flex">
															<a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.openEditModal(customer)} class="custom-action-button">
																<i class="fas fa fa-edit"></i>
															</a>
															<a href={`/administration/measurement/${customer.id}`} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} class="custom-action-button">
																<i class="fa fa-eye"></i>
															</a>
															<a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.openDeleteModal(customer)} class="custom-action-button">
																<i class="fa fa-trash"></i>
															</a>
														</div>
													</td>
												</tr>
											)
										})}

									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>

				<Modal show={this.state.showModal} onHide={this.closeModal}>
					<form onSubmit={this.createCustomer} onHide={this.closeModal} show={this.state.showModal}>
						<Modal.Header closeButton>
							<Modal.Title>Add new customer</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<div class="alert alert-success" role="alert">
									Customer created successfully!
								</div>
								<div class="alert alert-danger" role="alert">
									Could not create cutomer.
								</div>
							</div>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="first_name" class="form-label">First Name</label>
									<input type="text" name='first_name' value={this.state.activeItem.first_name} onChange={this.handleChange} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="last_name" class="form-label">Last Name</label>
									<input type="text" name='last_name' value={this.state.activeItem.last_name} onChange={this.handleChange} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="email" class="form-label">Email</label>
									<input type="email" value={this.state.activeItem.email} name='email' onChange={this.handleChange} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="phone" class="form-label">Phone</label>
									<input type="text" value={this.state.activeItem.phone} name='phone' onChange={this.handleChange} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="country" class="form-label">Country</label>
									<select name="country_id" onChange={self.handleChange} class="form-control mb-3"
										aria-label="Default select example" id="country">
										<option disabled selected>Select the customer country</option>
										{countries.map(function(country, index) {
											return (
												<option value={country.id}>{country.country_name}</option>
											)
										})}
									</select>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.closeModal}>
								Close
							</Button>
							<button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save</button>
						</Modal.Footer>
					</form>
				</Modal>

				<Modal show={this.state.showEditModal} onHide={this.closeEditModal}>
					<form onSubmit={this.updateCustomer} show={this.state.showModal}>
						<Modal.Header closeButton>
							<Modal.Title>Update customer</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<div class="alert alert-success" role="alert">
									Customer updated successfully!
								</div>
								<div class="alert alert-danger" role="alert">
									Could not update cutomer.
								</div>
							</div>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="first_name" class="form-label">First Name</label>
									<input type="text" name='first_name' value={this.state.editingDetails.first_name} onChange={this.handleChangeOnEdit} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="last_name" class="form-label">Last Name</label>
									<input type="text" name='last_name' value={this.state.editingDetails.last_name} onChange={this.handleChangeOnEdit} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="email" class="form-label">Email</label>
									<input type="email" value={this.state.editingDetails.email} name='email' onChange={this.handleChangeOnEdit} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="phone" class="form-label">Phone</label>
									<input type="text" value={this.state.editingDetails.phone} name='phone' onChange={this.handleChangeOnEdit} class="form-control" required />
								</div>
								<div class="col-sm-6">
									<label for="phone" class="form-label">Status</label>
									<input type="text" value={this.state.editingDetails.status} name='status' onChange={this.handleChangeOnEdit} class="form-control" required />
								</div>
								<div class="col-sm-12">
									<label for="country" class="form-label">Country</label>
									<select name="country_id" onChange={self.handleChangeOnEdit} class="form-control mb-3"
										aria-label="Default select example" id="country">
										<option disabled selected>Select the customer country</option>
										{countries.map(function(country, index) {
											return (
												<option value={country.id}>{country.country_name}</option>
											)
										})}
									</select>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.closeEditModal}>
								Close
							</Button>
							<button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save</button>
						</Modal.Footer>
					</form>
				</Modal>

				<Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
					<Modal.Header closeButton>
						<Modal.Title>Delete Customer</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Are you sure, you want to delete customer "{this.state.editingDetails.first_name}"?</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.closeDeleteModal}>
							Close
						</Button>
						<a onClick={() => this.deleteCustomer(this.state.editingDetails)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
					</Modal.Footer>
				</Modal>
			</>
		)

	}
}