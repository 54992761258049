class AuthService {
	
	constructor() {
        this.origin = window.location.origin;
    }
    
    constructURL(destination){
		var location = this.origin
		return location + destination 
	}
    
    async login(username, password) {
        try {
            const response = await fetch(this.constructURL("/api/api-token-auth/"), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const user = await response.json();
                return user;
            }

            throw new Error('Bad Credentials.');
        } catch (error) {
            console.error('Failed to login:', error);
            return null;
        }
    }

    async logout() {
        try {
            await fetch(this.constructURL("/api/logout/"), {
                method: 'POST',
                credentials: 'include',
            });

            // Limpa os dados do usuário após o logout bem-sucedido
            return true;
        } catch (error) {
            console.error('Failed to logout:', error);
            return false;
        }
    }

    async isAuthenticated() {
        try {
            const response = await fetch(this.constructURL("/api/is-authenticated/"), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', 
            });

            if (response.ok) {
                const data = await response.json();
                return data.is_authenticated; 
            }
            //console.log(origin)
            //console.log(data)
            // Se a resposta não for bem-sucedida, lança uma exceção ou retorna false
            throw new Error('Não foi possível verificar a autenticação do usuário.');
        } catch (error) {
            console.error('Erro ao verificar autenticação:', error);
            return false;
        }
    }
}

export default new AuthService();
