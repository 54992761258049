import React from "react";
import styles from '../../css/sb-admin-2.module.css'
import '../../css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'

class ProductDetails extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            product_details: [],
            inventors: [],
            vendors: [],
            activeItem: {
                id: null,
                description: '',
                main_image: '',
                cover_image: '',
                thumbnail_image_1: '',
                thumbnail_image_2: '',
                thumbnail_image_3: '',
                weight: '',
            },
            roleItem: {
                ip: null,
                description: '',
                main_image: '',
                cover_image: '',
                thumbnail_image_1: '',
                thumbnail_image_2: '',
                thumbnail_image_3: '',
                weight: '',
            },
            editing: false,
            showModal: false,
            showDeleteModal: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.fetchProductDetails = this.fetchProductDetails.bind(this)
        this.fetchIventory = this.fetchIventory.bind(this)
        this.fetchVendor = this.fetchVendor.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openDeleteModal = (role) => {
        this.setState({
            roleItem: role,
            editing: true,
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    fetchIventory() {
        fetch('http://localhost:8000/api/get-inventories/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    inventors: data
                })
            )
    }

    fetchVendor() {
        fetch('http://localhost:8000/api/get-vendor/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    vendors: data
                })
            )
    }

    fetchProductDetails() {
        fetch('http://localhost:8000/api/get-product-detail/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    product_details: data
                })
            )
    }

    componentWillMount() {
        this.fetchProductDetails()
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    handleChangeFile(e) {
        var { name, files } = e.target
        console.log(files);
        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: files[0],
            }
        })
    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            roleItem: {
                ...this.state.roleItem,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    startEdit(role) {
        this.setState({
            roleItem: role,
            editing: true,
        });

        this.openModal()
    }

    handleEdit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = `http://localhost:8000/api/inventories/${this.state.roleItem.id}/update/`;


        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.roleItem)
        }).then((response) => {
            this.fetchIventory()
            this.setState({
                roleItem: {
                    id: null,
                    description: '',
                    quantity: '',
                    stock_type: '',
                    arrival_date: '',
                    out_of_stock_date: '',
                    warehouse_id: '',
                    vendor_id: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    handleSubmit(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');
        var url = 'http://localhost:8000/api/product-detail/';

        var formData = new FormData();
        formData.append('description', this.state.activeItem.description);
        formData.append('main_image', this.state.activeItem.main_image);
        formData.append('cover_image', this.state.activeItem.cover_image);
        formData.append('thumbnail_image_1', this.state.activeItem.thumbnail_image_1);
        formData.append('thumbnail_image_2', this.state.activeItem.thumbnail_image_2);
        formData.append('thumbnail_image_3', this.state.activeItem.thumbnail_image_3);
        formData.append('weight', this.state.activeItem.weight);

        console.log(this.state.activeItem.thumbnail_image_3);

        fetch(url, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
            },
            body: formData,
        })
            .then((response) => {
                this.fetchProductDetails()
                this.setState({
                    activeItem: {
                        id: null,
                        description: '',
                        main_image: '',
                        cover_image: '',
                        thumbnail_image_1: '',
                        thumbnail_image_2: '',
                        thumbnail_image_3: '',
                        weight: '',
                    }
                });
            })
            .catch(function (error) {
                console.log('ERROR', error);
            });
    }

    deleteItem(role) {
        var csrftoken = this.getCookie('csrftoken');

        fetch(`http://localhost:8000/api/inventories/${role.id}/delete/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.fetchIventory();
        })

        this.closeDeleteModal()
    }

    render() {
        var self = this
        var inventors = self.state.inventors
        var product_details = self.state.product_details
        var vendors = self.state.vendors

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Product Details</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Products</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Description</label>
                                                <input type="text" name='description' value={this.state.activeItem.description} onChange={this.handleChange} class="form-control"
                                                    placeholder="description" required />
                                            </div>

                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Main image</label>
                                                <input type="file" name='main_image' onChange={this.handleChangeFile} class="form-control"
                                                    placeholder="main_image" required />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Cover image</label>
                                                <input type="file" name='cover_image' onChange={this.handleChangeFile} class="form-control"
                                                    placeholder="cover_image" required />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Thumbnail image 1</label>
                                                <input type="file" name='thumbnail_image_1' onChange={this.handleChangeFile} class="form-control"
                                                    placeholder="thumbnail_image_1" required />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Thumbnail image 2</label>
                                                <input type="file" name='thumbnail_image_2' onChange={this.handleChangeFile} class="form-control"
                                                    placeholder="thumbnail_image_2" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Thumbnail image 3</label>
                                                <input type="file" name='thumbnail_image_3' onChange={this.handleChangeFile} class="form-control"
                                                    placeholder="thumbnail_image_3" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Weight</label>
                                                <input type="number" name='weight' value={this.state.activeItem.weight} onChange={this.handleChange} class="form-control"
                                                    placeholder="weight" required />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="submit" id='submit' name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}></input>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">details</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>description</th>
                                            <th>main image</th>
                                            <th>cover image</th>
                                            <th>thumbnail image 1</th>
                                            <th>thumbnail image 2</th>
                                            <th>thumbnail image 3</th>
                                            <th>weight</th>
                                            <th>created on</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>description</th>
                                            <th>main image</th>
                                            <th>cover image</th>
                                            <th>thumbnail image 1</th>
                                            <th>thumbnail image 2</th>
                                            <th>thumbnail image 3</th>
                                            <th>weight</th>
                                            <th>created on</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {product_details.map(function (detail, index) {
                                            var date = new Date(detail.created_at)
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{detail.description}</td>
                                                    <td>{detail.main_image}</td>
                                                    <td>{detail.cover_image}</td>
                                                    <td>{detail.thumbnail_image_1}</td>
                                                    <td>{detail.thumbnail_image_2}</td>
                                                    <td>{detail.thumbnail_image_3}</td>
                                                    <td>{detail.weight}</td>
                                                    <td>{date.toLocaleDateString()}</td>
                                                    <td>
                                                        <div class="actions-container flex">
                                                            <a href={"/administration/products/"+detail.id} class="custom-action-button" style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-eye"></i>
                                                            </a>
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.openStaffDetailsModal(detail)} class="custom-action-button">
                                                                <i class="fas fa fa-edit"></i>
                                                            </a>
                                                            <a class="custom-action-button" onClick={() => self.openDeleteModal(detail)} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
                                                                <i class="fas fa fa-trash-alt"></i>
                                                            </a>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                {/* ====== MODALS ====== */}
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <form onSubmit={this.handleEdit} onHide={this.closeModal} show={this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Brand {this.state.roleItem.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="task" class="form-label">Description</label>
                                        <input type="text" name='description' value={this.state.roleItem.description} onChange={this.handleChangeOnEdit} class="form-control"
                                            placeholder="description" required />
                                    </div>

                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="task" class="form-label">Quantity</label>
                                        <input type="number" name='quantity' value={this.state.roleItem.quantity} onChange={this.handleChangeOnEdit} class="form-control"
                                            placeholder="quantity" required />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="task" class="form-label">Stock type</label>
                                        <input type="text" name='stock_type' value={this.state.roleItem.stock_type} onChange={this.handleChangeOnEdit} class="form-control"
                                            placeholder="stock_type" required />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="task" class="form-label">Arrival Date</label>
                                        <input type="date" name='arrival_date' value={this.state.roleItem.arrival_date} onChange={this.handleChangeOnEdit} class="form-control"
                                            placeholder="arrival_date" required />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="task" class="form-label">Out of stock date</label>
                                        <input type="date" name='out_of_stock_date' value={this.state.roleItem.out_of_stock_date} onChange={this.handleChangeOnEdit} class="form-control"
                                            placeholder="out_of_stock_date" required />
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete inventory?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteItem(this.state.roleItem)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>

            </>

        )
    }

}

export default ProductDetails