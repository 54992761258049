import React from "react";
import img from './media/CLtelp3493.jpeg'
import img1 from '../images/product-detail-01.jpg'
import img2 from '../images/product-detail-02.jpg'
import img3 from '../images/product-detail-03.jpg'
import product1 from '../images/product-01.jpg'
import heart01 from '../images/icons/icon-heart-01.png'
import heart02 from '../images/icons/icon-heart-02.png'
import close from '../images/icons/icon-close.png'

export default class Store extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            products: []
        }

    }

    fetchProducts() {
        fetch('http://localhost:8000/api/get-product/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    products: data
                })
            )
    }

    componentWillMount() {
        this.fetchProducts()
    }

    render() {
        var self = this
        var products = self.state.products
        console.log(products);
        return (
            <>
                <header class="masthead">
                    <div class="container">
                        <div class="masthead-subheading">Our Products!</div>
                    </div>
                </header>

                <section class="page-section" id="services">
                    <div class="container">
                        <div class="row" style={{ position: 'relative', height: '4037.62px' }}>
                            {
                                products.map(function (product, index) {
                                    return (
                                        <>
                                            <div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item women"
                                                style={{ position: 'absolute', left: '0%', top: '0px' }}>
                                                <div class="block2">
                                                    <div class="block2-pic hov-img0">
                                                        <img src={"http://localhost:8000" + product.main_image} alt="IMG-PRODUCT" />
                                                        <a href="#"
                                                            class="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                                                            Quick View
                                                        </a>
                                                    </div>
                                                    <div class="block2-txt flex-w flex-t p-t-14">
                                                        <div class="block2-txt-child1 flex-col-l ">
                                                            <a href={"/store/read/" + product.id} class="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                                                {product.name}
                                                            </a>
                                                            <span class="stext-105 cl3">
                                                                {product.price}
                                                            </span>
                                                        </div>
                                                        <div class="block2-txt-child2 flex-r p-t-3">
                                                            <a href="#" class="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                                                                <img class="icon-heart1 dis-block trans-04" src={heart01}
                                                                    alt="ICON" />
                                                                <img class="icon-heart2 dis-block trans-04 ab-t-l"
                                                                    src={heart02} alt="ICON" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>



                                    )
                                })
                            }
                            <div class="flex-c-m flex-w w-full p-t-45">
                                <a href="#" class="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04">
                                    Load More
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="btn-back-to-top" id="myBtn" style={{ display: 'none' }}>
                    <span class="symbol-btn-back-to-top">
                        <i class="zmdi zmdi-chevron-up"></i>
                    </span>
                </div>

                <div class="wrap-modal1 js-modal1 p-t-60 p-b-20">
                    <div class="overlay-modal1 js-hide-modal1"></div>
                    <div class="container">
                        <div class="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
                            <button class="how-pos3 hov3 trans-04 js-hide-modal1">
                                <img src={close} alt="CLOSE" />
                            </button>
                            <div class="row">
                                <div class="col-md-6 col-lg-7 p-b-30">
                                    <div class="p-l-25 p-r-30 p-lr-0-lg">
                                        <div class="wrap-slick3 flex-sb flex-w">
                                            <div class="wrap-slick3-dots">
                                                <ul class="slick3-dots" role="tablist" style={{}}>
                                                    <li class="slick-active" role="presentation"><img
                                                        src={img1} />
                                                        <div class="slick3-dot-overlay"></div>
                                                    </li>
                                                    <li role="presentation"><img src={img2} />
                                                        <div class="slick3-dot-overlay"></div>
                                                    </li>
                                                    <li role="presentation"><img src={img3} />
                                                        <div class="slick3-dot-overlay"></div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="wrap-slick3-arrows flex-sb-m flex-w"><button
                                                class="arrow-slick3 prev-slick3 slick-arrow" style={{}}><i
                                                    class="fa fa-angle-left" aria-hidden="true"></i></button><button
                                                        class="arrow-slick3 next-slick3 slick-arrow" style={{}}><i
                                                            class="fa fa-angle-right" aria-hidden="true"></i></button></div>
                                            <div class="slick3 gallery-lb slick-initialized slick-slider slick-dotted">
                                                <div class="slick-list draggable">
                                                    <div class="slick-track" style={{ opacity: 1, width: '1614px' }}>
                                                        <div class="item-slick3 slick-slide slick-current slick-active"
                                                            data-thumb={img1} data-slick-index="0"
                                                            aria-hidden="false" tabindex="0" role="tabpanel" id="slick-slide10"
                                                            aria-describedby="slick-slide-control10"
                                                            style={{ width: '538px', position: 'relative', left: '0px', top: '0px', zIndex: 999, opacity: 1 }}>
                                                            <div class="wrap-pic-w pos-relative">
                                                                <img src={img1} alt="IMG-PRODUCT" />
                                                                <a class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04"
                                                                    href={img1} tabindex="0">
                                                                    <i class="fa fa-expand"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="item-slick3 slick-slide"
                                                            data-thumb={img2} data-slick-index="1"
                                                            aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide11"
                                                            aria-describedby="slick-slide-control11"
                                                            style={{ width: '538px', position: 'relative', left: '-538px', top: '0px', zIndex: 998, opacity: 0 }}>
                                                            <div class="wrap-pic-w pos-relative">
                                                                <img src={img2} alt="IMG-PRODUCT" />
                                                                <a class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04"
                                                                    href={img2} tabindex="-1">
                                                                    <i class="fa fa-expand"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="item-slick3 slick-slide"
                                                            data-thumb={img3} data-slick-index="2"
                                                            aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide12"
                                                            aria-describedby="slick-slide-control12"
                                                            style={{ width: '538px', position: 'relative', left: '-1076px', top: '0px', zIndex: 998, opacity: 0 }}>
                                                            <div class="wrap-pic-w pos-relative">
                                                                <img src={img3} alt="IMG-PRODUCT" />
                                                                <a class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04"
                                                                    href={img3} tabindex="-1">
                                                                    <i class="fa fa-expand"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}