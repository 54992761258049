import React from "react";

class ContactUs extends React.Component {

    render() {
        return (
            <>
                <section class="page-section" id="contact">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-heading text-uppercase">Give us a shout</h2>
                            <h3 class="section-subheading text-muted">info@skyjacket.io</h3>
                        </div>
                        <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                            <div class="row align-items-stretch mb-5">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input class="form-control" id="name" type="text" placeholder="Seu Nome *"
                                            data-sb-validations="required" />
                                        <div class="invalid-feedback" data-sb-feedback="name:required">O campo nome é obrigatório.</div>
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" id="email" type="email" placeholder="Seu Email *"
                                            data-sb-validations="required,email" />
                                        <div class="invalid-feedback" data-sb-feedback="email:required">É necessário colocar um e-mail.</div>
                                        <div class="invalid-feedback" data-sb-feedback="email:email">Este e-mail não é válido.</div>
                                    </div>
                                    <div class="form-group mb-md-0">
                                        <input class="form-control" id="phone" type="tel" placeholder="Seu telefone *"
                                            data-sb-validations="required" />
                                        <div class="invalid-feedback" data-sb-feedback="phone:required">É necessário um número de telefone.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-textarea mb-md-0">
                                        <textarea class="form-control" id="message" placeholder="Sua mensagem *"
                                            data-sb-validations="required"></textarea>
                                        <div class="invalid-feedback" data-sb-feedback="message:required">É necessário colocar escrever uma mensagem.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-none" id="submitSuccessMessage">
                                <div class="text-center text-white mb-3">
                                    <div class="fw-bolder">Envio do formulário com sucesso!</div>
                                    Para ativar este formulário, inscreva-se em
                                    <br />
                                    <a
                                        href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                                </div>
                            </div>
                            <div class="d-none" id="submitErrorMessage">
                                <div class="text-center text-danger mb-3">Erro ao enviar mensagem!</div>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-primary btn-xl text-uppercase disabled" id="submitButton" type="submit">Enviar mensagem</button>
                            </div>
                        </form>
                    </div>
                </section>
            </>
        )
    }
}

export default ContactUs