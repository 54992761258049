import React from "react";
import img from './media/CLtelp3493.jpeg'
import '../template/css/slick.css'
import img1 from '../images/product-detail-01.jpg'
import img2 from '../images/product-detail-02.jpg'
import img3 from '../images/product-detail-03.jpg'

export default class ProductDetail extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            products: []
        }
    }

    fetchProducts() {
        fetch('http://localhost:8000/api/get-product-by-id/' + this.getProductDetailId() + '/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    products: data
                })
            )
    }

    getProductDetailId = () => {
        var url = window.location.href
        var arrayUrl = url.split('/')
        var clientId = arrayUrl[5]
        return clientId
    }

    componentWillMount() {
        this.fetchProducts()
    }


    render() {

        var products = this.state.products

        return (
            <>

                <div class="container">
                    <div class="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
                        <a href="index.html" class="stext-109 cl8 hov-cl1 trans-04">
                            Home
                            <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
                        </a>

                        <a href="product.html" class="stext-109 cl8 hov-cl1 trans-04">
                            Men
                            <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
                        </a>

                        <span class="stext-109 cl4">
                            Basketball Jersey
                        </span>
                    </div>
                </div>
                <section class="sec-product-detail bg0 p-t-65 p-b-60">
                    <div class="container">
                        {products.map(function (product, index) {
                            return (
                                <div class="row">

                                    <div class="col-md-6 col-lg-7 p-b-30">
                                        <div class="p-l-25 p-r-30 p-lr-0-lg">
                                            <div class="wrap-slick3 flex-sb flex-w">
                                                <div class="wrap-slick3-dots">
                                                    <ul class="slick3-dots" role="tablist" style={{}}>
                                                        <li class="slick-active" role="presentation"><img
                                                            src={"http://localhost:8000"+product.thumbnail_image_1} />
                                                            <div class="slick3-dot-overlay"></div>
                                                        </li>
                                                        <li role="presentation"><img src={"http://localhost:8000"+product.thumbnail_image_2} />
                                                            <div class="slick3-dot-overlay"></div>
                                                        </li>
                                                        <li role="presentation"><img src={"http://localhost:8000"+product.thumbnail_image_3} />
                                                            <div class="slick3-dot-overlay"></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="wrap-slick3-arrows flex-sb-m flex-w"><button
                                                    class="arrow-slick3 prev-slick3 slick-arrow" style={{}}><i class="fa fa-angle-left"
                                                        aria-hidden="true"></i></button><button
                                                            class="arrow-slick3 next-slick3 slick-arrow" style={{}}><i class="fa fa-angle-right"
                                                                aria-hidden="true"></i></button></div>
                                                <div class="slick3 gallery-lb slick-initialized slick-slider slick-dotted">
                                                    <div class="slick-list draggable">
                                                        <div class="slick-track" style={{ opacity: 1, width: '1725px' }}>
                                                            <div class="item-slick3 slick-slide slick-current slick-active"
                                                                data-thumb={"http://localhost:8000"+product.main_image} data-slick-index="0"
                                                                aria-hidden="false" tabindex="0" role="tabpanel" id="slick-slide10"
                                                                aria-describedby="slick-slide-control10"
                                                                style={{ width: '575px', position: 'relative', left: '0px', top: '0px', zIndex: 999, opacity: 1 }}>
                                                                <div class="wrap-pic-w pos-relative">
                                                                    <img src={"http://localhost:8000"+product.main_image} alt="IMG-PRODUCT" />
                                                                    <a class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04"
                                                                        href={"http://localhost:8000"+product.main_image} tabindex="0">
                                                                        <i class="fa fa-expand"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="item-slick3 slick-slide" data-thumb={"http://localhost:8000"+product.thumbnail_image_2}
                                                                data-slick-index="1" aria-hidden="true" tabindex="-1" role="tabpanel"
                                                                id="slick-slide11" aria-describedby="slick-slide-control11"
                                                                style={{ width: '575px', position: 'relative', left: '-575px', top: '0px', zIndex: 998, opacity: 0 }}>
                                                                <div class="wrap-pic-w pos-relative">
                                                                    <img src={"http://localhost:8000"+product.thumbnail_image_2} alt="IMG-PRODUCT" />
                                                                    <a class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04"
                                                                        href={"http://localhost:8000"+product.thumbnail_image_2} tabindex="-1">
                                                                        <i class="fa fa-expand"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="item-slick3 slick-slide" data-thumb={"http://localhost:8000"+product.thumbnail_image_3}
                                                                data-slick-index="2" aria-hidden="true" tabindex="-1" role="tabpanel"
                                                                id="slick-slide12" aria-describedby="slick-slide-control12"
                                                                style={{ width: '575px', position: 'relative', left: '-1150px', top: '0px', zIndex: 998, opacity: 0 }}>
                                                                <div class="wrap-pic-w pos-relative">
                                                                    <img src={"http://localhost:8000"+product.thumbnail_image_3} alt="IMG-PRODUCT" />
                                                                    <a class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04"
                                                                        href={"http://localhost:8000"+product.thumbnail_image_3} tabindex="-1">
                                                                        <i class="fa fa-expand"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-5 p-b-30">
                                        <div class="p-r-50 p-t-5 p-lr-0-lg">
                                            <h4 class="mtext-105 cl2 js-name-detail p-b-14">
                                                Lightweight Jacket
                                            </h4>
                                            <span class="mtext-106 cl2">
                                                $58.79
                                            </span>
                                            <p class="stext-102 cl3 p-t-23">
                                                Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare
                                                feugiat.
                                            </p>

                                            <div class="p-t-33">
                                                <div class="flex-w flex-r-m p-b-10">
                                                    <div class="size-204 flex-w flex-m respon6-next">
                                                        <div class="wrap-num-product flex-w m-r-20 m-tb-10">
                                                            <div class="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                                                                <i class="fs-16 zmdi zmdi-minus"></i>
                                                            </div>
                                                            <input class="mtext-104 cl3 txt-center num-product" type="number"
                                                                name="num-product" value="1" />
                                                            <div class="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                                                                <i class="fs-16 zmdi zmdi-plus"></i>
                                                            </div>
                                                        </div>
                                                        <button
                                                            class="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                                                            Add to cart
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="flex-w flex-m p-l-100 p-t-40 respon7">
                                                <div class="flex-m bor9 p-r-10 m-r-11">
                                                    <a href="#"
                                                        class="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100"
                                                        data-tooltip="Add to Wishlist">
                                                        <i class="zmdi zmdi-favorite"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </section>

            </>
        )
    }
}