import React from "react";
import styles from './css/sb-admin-2.module.css'
import './css/sb-admin-2.min.css'
import logo from './media/logo-1.jpeg'
import html2pdf from 'html2pdf.js';

export default class Contractual extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected_user: [],
            users: [],
            counter: 0,
        }

        this.downloadContractualPDF = this.downloadContractualPDF.bind(this)
    }

    componentWillMount() {
        this.fetchUser()
    }

    forceUpdate = () => {
        this.setState({ counter: this.state.counter + 1 });
    };

    downloadContractualPDF = (e) => {

        var { name, value } = e.target
        var users_list = this.state.users
        var user = users_list.find(function (users_list) {
            return users_list.id == value
        })
        this.state.selected_user = user
        this.forceUpdate()

        const inputElement = document.getElementById('meu-template');
        const options = {
            filename: 'contrato.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        html2pdf(inputElement, options);
    }

    fetchUser() {
        fetch('https://skyjacket.co.za/api/users-list/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    users: data
                })
            )
    }

    render() {

        var self = this
        var users = self.state.users

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Contractual</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Contractual</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="role" class="form-label">Staff</label>
                                            <select name="user" onChange={self.downloadContractualPDF} class={`${styles.formControl} mb-3`}
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the staff role</option>
                                                {users.map(function (user, index) {
                                                    return (
                                                        <option value={user.id}>{user.username}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                {/* ======= CONTRACTUAL PDF TEMPLATE */}
                <div class="d-none">
                    <div id="meu-template" className="meu-template">
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="table-responsive table-size">
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '240px' }}>Contrato de Trabalho Temporário</h5>
                                <p class="mt-60 ml-70">ENTRE</p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}><b>SKY JACKET, SOCIEDADE UNIPESSOAL, LIMITADA.</b> Sociedade por quotas de
                                    responsabilidade limitada, pessoa única de direito privado, com sede comercial na Rua de Imap No
                                    83,
                                    Cidade da Matola, Maputo, Moçambique, matriculada na Conservatória do Registo das Entidades
                                    Legais de Maputo, contribuinte fiscal Nº 401242007, neste acto representada pelo Exmo. Sr. Valdumar
                                    Melvin Walters, doravante individualmente designado por <b>CONTRATANTE,</b></p>
                                <p class="mt-40 ml-70">E</p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}><b>{this.state.selected_user.first_name} {this.state.selected_user.last_name},</b> nascido à dia, mês, ano, de nacionalidade Moçambicana, portador do Bilhete
                                    de Identidade Nº xxxxxxxxxxxxxxxxxx, emitido aos dia, mês, ano, pelo Serviços de Identificação da
                                    cidade de local, residente no xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx0, doravante individualmente
                                    designado por <b>CONTRATADO.</b></p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>Considerando que a Sky Jacket é uma empresa privada de Consultoria informática e vestuário, que está
                                    disposta a ceder temporariamente o seu profissional para a empresa Sky Jacket para satisfazer as
                                    necessidades provisórias desta última de utilizar mão-de-obra por tempo incerto.</p>
                                <p style={{ marginTop: '300px' }}></p>
                            </div>

                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive table-size">
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}><b>RESOLVEM</b> as partes homologar este Contrato de Trabalho Temporário que se regerá pelas
                                    seguintes cláusulas:</p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Primeira</h5>
                                <h5 class="ml-70" style={{ marginLeft: '370px' }}>Objecto</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>O <b>CONTRATANTE</b> neste acto encaminha o <b>CONTRATADO</b> para a empresa <b>Sky Jacket</b>, onde o
                                    mesmo se compromete a prestar trabalho, sob o regime jurídico da Lei 23/2007 de 1 de Agosto,
                                    regulamentada pelo artigo 80 e seguintes da redimensionada lei, na condição de o <b>CONTRATADO</b> atender as necessidades transitórias ligadas a execução de um tarefa ocasional ou serviço determinado e
                                    não duradouro.</p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Segunda</h5>
                                <h5 class="ml-70" style={{ marginLeft: '200px' }}>Categoria e funções do CONTRATADO</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>1. O <b>CONTRATADO</b> é admitido a serviço do <b>CONTRATANTE</b> com a categoria profissional de
                                    Cargo, na qual compromete-se a exercer as funções junto à empresa <b>Sky Jacket</b>, por conta e sob a
                                    autoridade desta última e do <b>CONTRATANTE</b> ou de quem legitimamente a representa, no teste
                                    Contrato de Trabalho</p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>2. Dado a natureza das actividades a realizar ao abrigo deste contrato, que requerem uma flexibilidade
                                    na realização dos deveres, responsabilidades, tarefas e funções do <b>CONTRATADO</b>, periodicamente, o
                                    <b>CONTRANTE</b> poderá determinar que o <b>CONTRATADO</b> realize outras funções, que correspondem
                                    as suas habilidades, experiência e formação e o <b>CONTRATADO</b> realize as funções em qualquer sector
                                    da empresa Sky Jacket, conforme lhe for determinado pela direcção da <b>Sky Jacket</b>, desde que tal não
                                    implique modificação substancial da sua posição, nem diminuição da remuneração</p>
                                <p style={{ marginTop: '170px' }}></p>
                            </div>

                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="table-responsive table-size">
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Terceira</h5>
                                <h5 class="ml-70" style={{ marginLeft: '295px' }}>Regime de Ocupação</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>O <b>CONTRATADO</b> desempenha as suas funções em regime de ocupação exclusiva, pelo que não
                                    poderá exercer trabalhos, remunerados ou não, para terceiros, sem prévia autorização por escrito do <b>CONTRATANTE</b>.</p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Quarta</h5>
                                <h5 class="ml-70" style={{ marginLeft: '260px' }}>Tipo e Duração do Contrato</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>1. Por se tratar de realizar tarefa ocasional, cuja sua duração se prevê ser igual ou superior a 06 meses,
                                    o presente contrato e celebrado por prazo incerto, ao abrigo do Artigo 44o
                                    da Lei No 23/2007 de 1 de
                                    Agosto.</p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>2. Este contrato terá início a partir de dia, mês, ano</p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>3. Este contrato poderá cessar a qualquer momento, sem que caiba em benefício de qualquer das partes
                                    de denunciar o contrato por escrito com antecedência mínima de sete dias.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Quinta</h5>
                                <h5 class="ml-70" style={{ marginLeft: '305px' }}>Período Probatório</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>O <b>CONTRATADO</b> está sujeito a um período probatório de quinze dias durante o qual qualquer das
                                    partes pode denunciar o contracto por escrito com antecedência mínima de sete dias, sem necessidades
                                    de invocação de justa causa e sem direito a indemnização.
                                </p>
                                <p style={{ marginTop: '170px' }}></p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive table-size">
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Sexta</h5>
                                <h5 class="ml-70" style={{ marginLeft: '305px' }}>Lugar de Trabalho</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>O local principal de trabalho ao abrigo do presente Contrato será o local onde será necessário realizar o
                                    serviço determinado e não duradouro, ficando desde já definida as instalações da Sky Jacket,
                                    localizadas na província de Maputo, como local principal de trabalho. O <b>CONTRARTADO</b>
                                    desempenha as suas funções de acordo com as instruções do <b>CONTRATANTE</b> e necessidades da Sky Jacket o que implica que o <b>CONTRATADO</b> poderá eventualmente prestar serviços em outros locais
                                    determinadas pela Sky Jacket.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Setima</h5>
                                <h5 class="ml-70" style={{ marginLeft: '270px' }}>Deveres do CONTRATADO</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>1. O <b>CONTRATADO</b> obriga-se a, no desempenho das suas funções a acatar integralmente a todas as
                                    ordens, instruções e normas consagradas no regulamento interno da Sky Jacket bem como aos deveres
                                    gerais fixados na Lei de Trabalho e demais legislação Aplicável.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Oitava</h5>
                                <h5 class="ml-70" style={{ marginLeft: '270px' }}>Deveres do CONTRATADO</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>1. O <b>CONTRATADO</b> tem os seguintes deveres e lealdade as funções para e obrigações para além dos
                                    deveres geram constantes da Lei de Trabalho, de outros diplomas legais, do Regulamento interno da
                                    Sky Jacket:<br />
                                    a) Desempenhar com zelo, competência e lealdade as funções que lhe forem confiadas;<br />
                                    b) Cumprir o horário de trabalho estabelecido pela Sky Jacket;<br />
                                    c) Contribuir para a criação de um bom ambiente de trabalho, tomando em consideração especial que o
                                    trabalho vai reunir pessoas de origem e cultura diferentes;<br />
                                    d) Respeitar a legislação em vigor na República de Moçambique, as normas e Regulamentos Internos
                                    em vigor na Sky Jacket;<br />
                                </p>
                                <p style={{ marginTop: '60px' }}></p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive table-size">
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>e) Participar, quando solicitado, nos cursos de formação e aperfeiçoamento promovidos pela Sky
                                    Jacket.;<br />
                                    f) Não utilizar para fins pessoais ou alheios ao serviço, os locais, equipamento, veículos e outros bens
                                    ou serviços centro de trabalho;<br />
                                    g) Não aceder ou introduzir qualquer programa de informática (software) nos computadores e
                                    equipamentos de informação do Sky Jacket;<br />
                                    h) Manter sigilo sobre as actividades e / ou quaisquer documento ou informações que obtenha, no
                                    exercício deste contrato a terceiros informação relativa a actividade do Sky Jacket. E seus afiliados;<br />
                                    I) Não realizar qualquer actividade concorrente ou contra os interesses da Sky Jacket
                                </p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>2. O <b>CONTRATADO</b> compromete-se a respeitar as políticas e procedimentos internos da Sky Jacket.
                                    No que diz respeito as regras e limites de utilização das tecnologias de informação na empresa,
                                    nomeadamente do correio electrónico, acesso a Internet e o uso de telefones e telemóveis, que são
                                    postos a sua disposição exclusivamente para fins do serviço da Sky Jacket. O <b>CONTRATADO</b> declara
                                    ao assinar este Contrato que compreende e aceita que se uso para fins pessoais desta tecnologias, não
                                    lhe é protegido o direito a confidencialidade e podem ser-lhe debitados os custos relativos ao uso não
                                    autorizado de tais tecnologias.
                                </p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    3.O <b>CONTRATADO</b> será igualmente responsável por qualquer dano causado em objecto de
                                    propriedade e ou posse da Sky Jacket. Ficando desde já o <b>CONTRATANTE</b> autorizada a fazer o
                                    desconto da remuneração mensal do <b>CONTRATADO</b> quer o dano tenha sido causado por dolo.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Nona</h5>
                                <h5 class="ml-70" style={{ marginLeft: '170px' }}>Remuneração e periodicidade de pagamentos</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    1. Pelo trabalho prestado ao abrigo deste Contrato, o <b>CONTRATANTE</b> pagará ao <b>CONTRATADO</b>
                                    um salário bruto de <b>X XXX, XXMZN (escrever o vallor por extenso), que após deduções legais
                                        perfaz um salário liquído de X XXX, XXMZN (escrever o vallor por extenso).</b><br />
                                    2. Estes valores serão pagos mensalmente em dinheiro ou transferência bancária ou cheque, nos dias
                                    designados como dias de pagamento de salário nomeadamente no dia 01 de cada mês.
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive table-size">
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '320px' }}>Cláusula Décima</h5>
                                <h5 class="ml-70" style={{ marginLeft: '320px' }}>Horário Trabalho</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    1. O Horário de trabalho esta de acordo com o artigo 85, No 2 da Lei do Trabalho, Lei No 23/2007, de 1
                                    de Agosto de 2007.<br />
                                    2. Assim o período normal de trabalho será de 08 horas diárias, realizados em regime de turnos
                                    apresentados e definidos pela Sky Jacket.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '270px' }}>Cláusula Décima Primeira</h5>
                                <h5 class="ml-70" style={{ marginLeft: '280px' }}>Trabalho Extraordinário</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    1. A natureza do trabalho poderá eventualmente exigir a prestação de trabalho extraordinário. O
                                    CONTRATADO aceita que, desde que lhe seja dado um pré-aviso razoável não se recusará a trabalhar
                                    fora das horas normais de trabalho.<br />
                                    2. O trabalho extraordinário será remunerado nos termos da lei do trabalho em vigor.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '270px' }}>Cláusula Décima Segunda</h5>
                                <h5 class="ml-70" style={{ marginLeft: '260px' }}>Dias de Descanso Semanais</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    O <b>CONTRATADO</b> terá dias de descanso semanal de acordo com o plano de turnos/trabalho definidos
                                    pelo Sky Jacket.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '270px' }}>Cláusula Décima Terceira</h5>
                                <h5 class="ml-70" style={{ marginLeft: '350px' }}>Feriados</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    O <b>CONTRATADO</b> que trabalha num dia de Feriado será remunerado de acordo com a Lei de
                                    Trabalho, com uma importância correspondente a remuneração do trabalho normal (salário base), por
                                    cada hora trabalhada nesse Feriado.
                                </p>
                                <p style={{ marginTop: '60px' }}></p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive table-size">
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '270px' }}>Cláusula Décima Quarta</h5>
                                <h5 class="ml-70" style={{ marginLeft: '320px' }}>Férias Anuais</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    1. O <b>CONTRATADO</b> tem direito a férias de acordo com o artigo 99 e 100 da Lei de Trabalho as quais
                                    serão gozadas, de acordo com o plano de férias definido pela Sky Jacket. Os dias de férias são contados
                                    em dias consecutivos de calendário.<br />
                                    2. Se o Contrato de trabalho termina antes da data do início do período de férias, as férias serão
                                    substituídas por uma remuneração suplementar equivalente aos dias de férias acumulados e não
                                    gozados pelo <b>CONTRATADO.</b>
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '270px' }}>Cláusula Décima Quinta</h5>
                                <h5 class="ml-70" style={{ marginLeft: '230px' }}>Saúde e Segurança no Trabalho</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    O <b>CONTRATADO</b> deve zelar pela sua própria segurança e a de outras pessoas que se podem ver
                                    afectadas pelos seus actos e omissões no trabalho, assim como deve colaborar com o <b>CONTRATANTE</b>
                                    em matéria de Higiene e Segurança no Trabalho.
                                </p>
                                <h5 class="mt-60 ml-70" style={{ marginLeft: '270px' }}>Cláusula Décima Sexta</h5>
                                <h5 class="ml-70" style={{ marginLeft: '270px' }}>Resolução de disputas</h5>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>
                                    1. Todos os conflitos emergentes deste Contrato serão em primeiro lugar resolvidos amigavelmente.<br />
                                    2. No caso de não haver resolução amigável, as matérias em disputa devem ser dirimidas por mediação
                                    e, não havendo solução serão remetidas ao tribunal Judicial da cidade de Maputo, com renúncia a
                                    qualquer outro.<br />
                                    E assim e por estarem juntos e contratados, comprometem-se a dar fiel e cabal cumprimento ao que o
                                    mesmo e assinado em duas vias de um só teor.

                                </p>
                                <p style={{ marginTop: '120px' }}></p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive table-size">

                                <p class="mt-60 ml-70"></p>
                                <p class="mt-40 ml-70" style={{ marginLeft: '270px' }}>
                                    Matola aos, dia, mês, ano
                                </p>
                                <p class="mt-40 ml-70" style={{ marginLeft: '300px' }}>
                                    <b>CONTRATANTE</b>
                                </p>
                                <hr style={{ marginLeft: '200px', width: '320px', marginTop: '30px' }} />
                                <p class="ml-70" style={{ marginLeft: '270px' }}>
                                    Valdumar Melvin Walters
                                </p>
                                <p class="ml-70" style={{ marginLeft: '310px', marginTop: '-15px' }}>
                                    (Director Geral)
                                </p>
                                <p class="mt-40 ml-70" style={{ textAlign: 'justify' }}>Declaro ter recebido uma cópia do presente Contrato de Trabalho. Compreendo e aceito os termos de
                                    trabalho contidos neste documento.
                                </p>
                                <p class="mt-40 ml-70" style={{ marginLeft: '300px' }}>
                                    <b>CONTRATADO</b>
                                </p>
                                <hr style={{ marginLeft: '200px', width: '320px', marginTop: '30px' }} />
                                <p class="ml-70" style={{ marginLeft: '270px' }}>
                                    Nome Completo
                                </p>
                                <p class="ml-70" style={{ marginLeft: '310px', marginTop: '-15px' }}>
                                    (Cargo que ocupa)
                                </p>
                                <p style={{ marginTop: '80px' }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}