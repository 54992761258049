import React from "react";

class AboutUs extends React.Component {

    render() {
        return (
            <>
                <section class="page-section" id="about">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-heading text-uppercase">About us</h2>
                            <h3 class="section-subheading text-muted"> Sky Jacket is a garment and software tech hybrid founded in February of 2015.
                                With our routes in garment production, we have profound experience in tailoring and an exceptional perspective of how
                                personal products and services can give an individual and organization a boost in image and confidence.
                                We aspire to evolve with our customers as we merge garments with electronic and software technology. We believe that
                                we have a lot of ground to cover and look forward to working with you so that we can all fill our space.
                            </h3>
                        </div>
                        <ul class="timeline">
                            <li>
                                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/matric.jpg"
                                    alt="..." />
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>2015</h4>
                                        <h4 class="subheading">Where it all started</h4>
                                    </div>
                                    <div class="timeline-body">
                                        <p class="text-muted">Matric Jacket innovators! We began by making custom matric jackets in a way that
                                            allowed high school finalists to express their identity through their jacket design</p>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/suit.jpg"
                                    alt="..." /></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>2019</h4>
                                        <h4 class="subheading">Press play on the suit game!</h4>
                                    </div>
                                    <div class="timeline-body">
                                        <p class="text-muted">Inspired by the idea of becoming a company of master tailors we finally applied our jacket experience
                                            in suit design, cutting production.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/capulua.jpg"
                                    alt="..." style={{height:'100%', width:'100%'}} /></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>2020</h4>
                                        <h4 class="subheading">Capulua</h4>
                                    </div>
                                    <div class="timeline-body">
                                        <p class="text-muted">After looking at the online market, we realized that there is a lack of authentic African representation.
                                            This lead us to create our first software solution. An online store created in house ;D.</p>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/show.jpg"
                                    alt="..." style={{height:'100%',width:'100%'}} /></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>2022</h4>
                                        <h4 class="subheading">Fashion time!</h4>
                                    </div>
                                    <div class="timeline-body">
                                        <p class="text-muted">We are a tailoring company. But that didn't stop us from trying out the fashion scene as we participated
                                            in our first fashion show preview experience.</p>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image">
                                    <h4>
                                        Participate
                                        <br />
                                        In creating
                                        <br />
                                        History!
                                    </h4>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>

                <section class="page-section bg-light" id="team">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">Who phlys the ship?</h2>
                <h3 class="section-subheading text-muted">Meet the ones who make it all happen.</h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="assets/img/team/s.png" alt="..." />
                        <h4>Salmina (Mestre)</h4>
                        <p class="text-muted">Head Tailor</p>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/sky.jacket/?hl=en"><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/search/top?q=sky%20jacket"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/sky-jacket/"><i class="fab fa-linkedin-in"></i></a>                     
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="assets/img/team/m.jpg" alt="..." />
                        <h4>Miguel (MJ)</h4>
                        <p class="text-muted">Tailor</p>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/sky.jacket/?hl=en"><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/search/top?q=sky%20jacket"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/sky-jacket/"><i class="fab fa-linkedin-in"></i></a>                     
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="assets/img/team/e.png" alt="..." />
                        <h4>Ernesto (Boss Sitoe)</h4>
                        <p class="text-muted">Marketing Manager</p>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/sky.jacket/?hl=en"><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/search/top?q=sky%20jacket"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/sky-jacket/"><i class="fab fa-linkedin-in"></i></a>                     
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="assets/img/team/b.png" alt="..." />
                        <h4>Belucha (Bella)</h4>
                        <p class="text-muted">Administration</p>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/sky.jacket/?hl=en"><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/search/top?q=sky%20jacket"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/sky-jacket/"><i class="fab fa-linkedin-in"></i></a>                     
                    </div>
                </div>
            </div>
            <div class="row">
            	<div class="col-12 col-md-6 col-lg-2">
                    
                </div>
                <div class="col-12 col-md-6 col-lg-2">
                    
                </div>
                <div class="col-12 col-md-12 col-lg-4">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="assets/img/team/v.png" alt="..." />
                        <h4>Valdumar (Val G)</h4>
                        <p class="text-muted">Founder & Software developer</p>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.instagram.com/sky.jacket/?hl=en"><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/search/top?q=sky%20jacket"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/sky-jacket/"><i class="fab fa-linkedin-in"></i></a>                     
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2">
                    
                </div>
                <div class="col-12 col-md-6 col-lg-2">
                    
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 mx-auto text-center">
                </div>
            </div>
        </div>
    </section>
            </>
        )
    }
}

export default AboutUs