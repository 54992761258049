import React from "react";
import styles from './css/sb-admin-2.module.css'
import './css/sb-admin-2.min.css'

export default class Disciplinary extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [],
        }

    }

    componentWillMount() {
        this.fetchUser()
    }

    fetchUser() {
        fetch('https://skyjacket.co.za/api/users-list/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    users: data
                })
            )
    }

    render() {

        var self = this
        var users = self.state.users

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Disciplinary</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Disciplinary</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="role" class="form-label">Staff</label>
                                            <select name="user" onChange={self.openPaySlipModal} class={`${styles.formControl} mb-3`}
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the staff role</option>
                                                {users.map(function (user, index) {
                                                    return (
                                                        <option value={user.id}>{user.username}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}