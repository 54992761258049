import React from "react";
import html2pdf from 'html2pdf.js';
import './css/geometric.css'
import logo from './media/logo-1.jpeg'

class Dashboard extends React.Component {

    handleDownloadPDF = () => {
        const inputElement = document.getElementById('meu-template');
        html2pdf(inputElement);
    };

    render() {
        return (
            <>
                
            </>
        )
    }
}

export default Dashboard