import './App.css';
import TemplateAdmin from './admin/template/template';
import Template from './template/Template';


function App() {
  
  var url = window.location.href
  var route = url.split('/')
  return (
    <>
      {route[3] === 'administration' ? <TemplateAdmin/> : <Template />}
    </>
  );
}

export default App;

// import ProductTeste  from './products';
// import contents from './content';

// export default function App() {
//     return(
//            <div className='App'>
//                {contents.map(contents => (
//                    <ProductTeste 
//                        key={contents.id}
//                        image={contents.image}
//                        name={contents.name}
//                        price={contents.price}
//                        totalSales={contents.totalSales}
//                        timeLeft={contents.timeLeft}
//                        rating={contents.rating}
//                    />
//                ))}
//            </div>
//     )
// }